<template>
	<form class="form-inline p-8 rounded bg-gray-300 space-y-8" @submit="execute">
		<div class="flex gap-8">
			<div class="form-group flex-grow">
				<label for="input-name">Name</label>
				<input type="search" name="name" id="input-name" class="form-control" :value="name" placeholder="www.example.com">
			</div>
			<div class="form-group flex-shrink-0">
				<label for="input-type">Type</label>
				<TheDnsTypeInput :selected="type"/>
			</div>
			
			<div class="flex-shrink-0 mt-auto">
				<button type="submit" class="btn btn-primary">Submit</button>
			</div>
		</div>
	</form>
</template>

<script>
import TheDnsTypeInput from './TheDnsTypeInput';

export default {
	name: 'DNSLookupForm',
	props: {
		name: String,
		type: String,
	},
	emits: {
		submit: ({ name, type }) => {
				if (name && type) {
					return true
				} else {
					console.warn('name or type missing')
					return false
				}
			}
	},
	components: {
		TheDnsTypeInput
	},
	methods: {
		execute(event) {
			event.preventDefault();

			const form = new FormData(event.target);
			const name = form.get('name');
			const type = form.get('type');

			this.$emit('submit', {name, type});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
