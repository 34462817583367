<template>
	<div class="dns-lookup">
		<div class="container space-y-4">
			<h1>Reverse DNS Lookup (PTR)</h1>
			<p>Use this form to perform a reverse DNS query.</p>

			<DnsReverseLookupForm :name="$route.params.name" :type="$route.params.type" @submit="performLookup"/>
			<DnsLookupResults v-show="queryAnswers.length > 0" v-model:answers="queryAnswers"/>
			<DnsLookupNxDomain v-show="queryStatus === 3"/>
		</div>
	</div>
</template>


<script>
import axios from 'axios';
import RRTypes from '@/lib/DnsResourceRecordTypes';
import RCode from '@/lib/DnsRCodes';
import DnsReverseLookupForm from '@/components/DnsReverseLookupForm.vue'
import DnsLookupResults from '@/components/DnsLookupResults.vue'
import DnsLookupNxDomain from '@/components/DnsLookupNxDomain.vue'

export default {
	name: 'DNS-ReverseLookup',
	components: {
		DnsReverseLookupForm,
		DnsLookupResults,
		DnsLookupNxDomain,
	},
	data() {
		return {queryAnswers: [], queryStatus: null}
	},
	methods: {
		performLookup(query) {
			this.queryAnswers = [];

			query.type = 12;

			const ptrName = query.name.split('.').reverse().join('.');

			query.name = `${ptrName}.in-addr.arpa`;
			
			axios.get(`https://dns-lookup.z4xh.workers.dev/?name=${query.name}&type=${query.type}`).then((response) => {
				this.queryStatus = response.data.Status;

				// Successful query
				if (response.data.Status === RCode.NOERROR && response.data.Answer) {
					response.data.Answer.forEach((answer) => {
						if (parseInt(answer.type) === parseInt(query.type)) {
							console.log(RRTypes.toString(answer.type));
							this.queryAnswers.push({...answer, label: RRTypes.toString(answer.type)});
						}
					});

					this.$emit('queryAnswers:update', this.queryAnswers)
				}
			});
		}
	}
}
</script>