<template>
	<div class="dns-lookup">
		<div class="container space-y-4">
			<h1>DNS Lookup</h1>
			<p>Use this form to perform a DNS query.</p>

			<DnsLookupForm :name="$route.params.name" :type="$route.params.type" @submit="performLookup"/>
			<DnsLookupResults  v-show="queryAnswers.length > 0"  v-model:answers="queryAnswers"/>
			<DnsLookupNxDomain v-if="queryStatus === 3"/>
		</div>
	</div>
</template>


<script>
import axios from 'axios';
import RRTypes from '@/lib/DnsResourceRecordTypes';
import RCode from '@/lib/DnsRCodes';
import DnsLookupForm from '@/components/DnsLookupForm.vue'
import DnsLookupResults from '@/components/DnsLookupResults.vue'
import DnsLookupNxDomain from '@/components/DnsLookupNxDomain.vue'

export default {
	name: 'DNS-Lookup',
	components: {
		DnsLookupForm,
		DnsLookupResults,
		DnsLookupNxDomain,
	},
	data() {
		return {queryAnswers: []}
	},
	methods: {
		performLookup(query) {
			this.queryAnswers = [];

			axios.get(`https://dns-lookup.z4xh.workers.dev/?name=${query.name}&type=${query.type}`).then((response) => {
				this.queryStatus = response.data.Status;

				// Successful query
				if (response.data.Status === RCode.NOERROR && response.data.Answer) {
					response.data.Answer.forEach((answer) => {
						if (parseInt(answer.type) === parseInt(query.type)) {
							console.log(RRTypes.toString(answer.type));
							this.queryAnswers.push({...answer, label: RRTypes.toString(answer.type)});
						}
					});

					this.$emit('queryAnswers:update', this.queryAnswers)
				}
			});
		}
	}
}
</script>