<template>
	<router-link v-if="type === 1 || type === 28" :to="{ name: 'ip-lookup', params: { ip: data, } }">{{ data }}</router-link>

	<code v-else>{{ data }}</code>
</template>

<script>
export default {
	name: 'DnsLookupResultCellData',
	props: {
		type: Number,
		data: String,
	},
	setup() {
		// ...
	},
	data() {
		// ...
	}
}
</script>
