<template>
	<form class="form-inline p-8 rounded bg-gray-300 space-y-8" @submit="execute">
		<div class="flex gap-8">
			<div class="form-group flex-grow">
				<label for="input-name">Name</label>
				<input type="search" name="name" id="input-name" class="form-control" :value="name" placeholder="127.0.0.1">
			</div>
			
			<div class="flex-shrink-0 mt-auto">
				<button type="submit" class="btn btn-primary">Submit</button>
			</div>
		</div>
	</form>
</template>

<script>
export default {
	name: 'DNSReverseLookupForm',
	props: {
		name: String,
	},
	emits: {
		submit: ({ name }) => {
				if (name) {
					return true
				} else {
					console.warn('name missing')
					return false
				}
			}
	},
	methods: {
		execute(event) {
			event.preventDefault();

			const form = new FormData(event.target);
			const name = form.get('name');

			this.$emit('submit', {name});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
