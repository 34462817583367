<template>
	<select name="type" id="input-type" class="form-control" v-model="type">
		<option v-for="option in options" :key="option.index" :value="option.value" :title="`value: ${option.value}`">{{ option.text }}</option>
	</select>
</template>
<script>
import _ from 'lodash';
import DnsRecordTypes from '../lib/DnsResourceRecordTypes';

export default {
	name: 'TheDnsTypeInput',
	props: {
		selected: String,
	},
	setup() {
		
	},
	data(props) {
		return {
			type: DnsRecordTypes.forceInt(props.selected) || 1,
			options: _.map(DnsRecordTypes.getTypes(), (value, key) => {
				return { text: key, value: value };
			})
		}
	}
}
</script>
