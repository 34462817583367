<template>
	<div class="alert alert-info">
		Response status: NXDOMAIN
	</div>
</template>

<script>
export default {
	name: 'DNSLookupNXDomain',
}
</script>
