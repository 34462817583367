<template>
	<table class="table">
		<thead>
			<tr>
				<th>Type</th>
				<th>Name</th>
				<th>Data</th>
				<th>TTL</th>
				<th>DNSSEC</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="answer in answers" v-bind:key="answer.name">
				<td><span class="label" :class="'label-' + answer.label" :title="`type code: ${answer.type}`">{{ answer.label }}</span></td>
				<td>{{ answer.name }}</td>
				<td><DnsLookupResultCellData :type="answer.type" :data="answer.data"/></td>
				<td>{{ answer.TTL }}</td>
				<td>?</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import DnsLookupResultCellData from './DnsLookupResultCellData';

export default {
	name: 'DNSLookupResults',
	props: {
		answers: Array,
	},
	components: {
		DnsLookupResultCellData,
	}
}
</script>

<style scoped>

</style>
